<template>
<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" data-name="025_SCIENCE">
 <g class="layer">
  <title>Layer 1</title>
  <path d="m15.95,12.9l-8,0a3,3 0 0 1 -3,-3l0,-4a3,3 0 0 1 3,-3l8,0a3,3 0 0 1 3,3l0,4a3,3 0 0 1 -3,3zm-8,-8a1,1 0 0 0 -1,1l0,4a1,1 0 0 0 1,1l8,0a1,1 0 0 0 1,-1l0,-4a1,1 0 0 0 -1,-1l-8,0z" fill="#1F2937" id="svg_1"/>
  <path d="m9.95,8.9a1.05,1.05 0 0 1 -0.71,-0.29a1,1 0 0 1 0.9,-1.71a0.6,0.6 0 0 1 0.19,0.06a0.56,0.56 0 0 1 0.17,0.09l0.16,0.12a1,1 0 0 1 -0.71,1.73z" fill="#1F2937" id="svg_2"/>
  <path d="m13.95,8.9a1,1 0 0 1 -0.71,-1.71a1,1 0 0 1 1.42,1.42a1,1 0 0 1 -0.16,0.12a0.56,0.56 0 0 1 -0.17,0.09a0.6,0.6 0 0 1 -0.19,0.06l-0.19,0.02z" fill="#1F2937" id="svg_3"/>
  <path d="m11.95,3.9a1,1 0 0 1 -1,-1l0,-1a1,1 0 0 1 2,0l0,1a1,1 0 0 1 -1,1z" fill="#1F2937" id="svg_4"/>
  <path d="m8.95,21.9a1,1 0 0 1 -1,-1l0,-3a1,1 0 0 1 2,0l0,3a1,1 0 0 1 -1,1z" fill="#1F2937" id="svg_5"/>
  <path d="m14.95,21.9a1,1 0 0 1 -1,-1l0,-3a1,1 0 0 1 2,0l0,3a1,1 0 0 1 -1,1z" fill="#1F2937" id="svg_6"/>
  <path d="m14.95,18.9l-6,0a1,1 0 0 1 -1,-1l0,-6a1,1 0 0 1 1,-1l6,0a1,1 0 0 1 1,1l0,6a1,1 0 0 1 -1,1zm-5,-2l4,0l0,-4l-4,0l0,4z" fill="#1F2937" id="svg_7"/>
  <path d="m4.95,16.9a1,1 0 0 1 -0.89,-0.55a1,1 0 0 1 0.44,-1.34l4,-2a1,1 0 1 1 0.9,1.78l-4,2a0.93,0.93 0 0 1 -0.45,0.11z" fill="#1F2937" id="svg_8"/>
  <path d="m18.95,16.9a0.93,0.93 0 0 1 -0.45,-0.11l-4,-2a1,1 0 1 1 0.9,-1.78l4,2a1,1 0 0 1 0.44,1.34a1,1 0 0 1 -0.89,0.55z" fill="#1F2937" id="svg_9"/>
  <line fill="none" id="svg_10" x1="10.59" x2="13.18" y1="14.75" y2="14.75"/>
  <line fill="none" id="svg_13" x1="11.1" x2="10.59" y1="14.85" y2="15.25"/>
  <path d="m11.91,16.27a0.66,1 0 0 1 -0.66,-1l0,-1a0.66,1 0 0 1 1.31,0l0,1a0.66,1 0 0 1 -0.66,1z" fill="#7f0000" id="svg_16" transform="matrix(1 0 0 1 0 0)"/>
  <path d="m16.96,19.68a0.66,1 0 0 1 -0.66,-1l0,-1a0.66,1 0 0 1 1.31,0l0,1a0.66,1 0 0 1 -0.66,1z" fill="#7f0000" id="svg_17" transform="matrix(1 0 0 1 0 0) rotate(90 16.1613 13.9664)"/>
 </g>
</svg>

</template>
